//import { getEvents } from "../../../utils/api";
//import DisplayDate from "../../../components/elements/display-date";
import {useEffect, useState, useCallback } from "react"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
//import { isSameDay, parseISO } from "date-fns";
import {classnames} from 'tailwindcss-classnames';
import SellingEvent from "./selling-event";

const UpcomingEvents = ({data}) => {
    const [selected, setSelected] = useState()

    function processEvents(e) {
        let events = e.reduce((map, entry) => {
            let d = new Date(entry.attributes.startsAt).toDateString()
 
            if (map) {
                if (map.has(d)) {
                    let a = map.get(d);
                    a.push(entry)
                } else {
                    let a = new Array()
                    a.push(entry)
                    map.set(d, a)
                }
            }
            return map;
        }, new Map())
        return events;
    }

    function tileClassName({date, view}) {
        if (view === 'month' && evts && evts.size > 0) {
            let d = date.toDateString()
            if (evts.get(d)) {
                return classnames('ring-4 ring-crimson ring-offset-4 ring-offset-slate-50 dark:ring-offset-slate-900')
            }
        }
    }
    
    function onChange(value, event) {
        //console.log(`onchange value: ${value} event: ${event}`)
        setSelected(value.toDateString())
    }

    const evts = processEvents(data.events)

    return (<>
        <div key="_upcomingevents" className="container flex flex-col gap-20 px-12 align-top py-12">
            <h1 className="text-2xl font-mono">{data.title}</h1>
            <div className="flex flex-col lg:flex-row">
                <div className="flex-auto">
                    <Calendar onChange={onChange} tileClassName={tileClassName} key="_calendar"/>
                </div>
                <div className="flex-initial"></div>
                <div className="flex-auto flex-col center-content">
                    <div className="flex font-sans-serif text-lg pb-2">Events for {selected}</div>
                    <div className="flex">
                        <ul>
                        {
                            (evts.has(selected) === true) ? evts.get(selected).map( (v) => 
                                <li key={v.attributes.id}><SellingEvent event={v.attributes} id={v.attributes.id}></SellingEvent></li>
                            ): 'no scheduled events' 
                        }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)

}



export default UpcomingEvents