import className from "classnames"
//import CollapsibleItem from "../elements/collapsible-item"
import { PlusCircleIcon } from "@heroicons/react/24/outline"
import { EnvelopeIcon } from "@heroicons/react/24/solid"
import { PhoneIcon } from "@heroicons/react/24/solid"
import ButtonLink from '../../elements/button-link'
import { getButtonAppearance } from '../../../utils/button'

const FrequentlyAskedQuestions = ({ data }) => {
  return (
    // <div className="container flex flex-col items-center md:flex-row gap-20 px-8 align-top mt-6">
    <div className="container max-w-4xl mx-at p-6">  
      <div className="flex-auto">
        {/* <p className="flex mb-2 font-cursive text-crimson text-2xl">{data.title}</p> */}
        <p className="text-3xl font-bold text-center mb-8">{data.title}</p>
        <p className="mb-2">{data.description}</p>

        {/* List of contact information */}
        <ul className="mt-4">
          {data.contactInformation.map((contact) => (
            <li key={contact.id} className="m-1">
              <div className="flex items-center">
                <div className="flex w-5 h-5 shrink-0 grow-0 rounded-full bg-alert text-crimson items-center justify-center">
                  {contact.contactType === "email" ? (
                    <EnvelopeIcon className="w-3 h-3 inline text-white" />
                  ) : contact.contactType === "telephone" ? (
                    <PhoneIcon className="w-3 h-3 inline text-white" />
                  ) : (
                    <PlusCircleIcon className="w-4 h-4 inline text-white" />
                  )}
                </div>
                <div className="pl-1">{contact.info}</div>
              </div>
            </li>
          ))}
        </ul>
        
      </div>

      {/* List of Questions and Answers */}
      <div className="flex">
        <ul className="">
          {data.questionsAnswers.map((qna) => (
            <li key={qna.id} className="mb-2">
              <details className=""> 
                <summary className="cursor-pointer bg-opacity-30 bg-secondary-500 py-1 px-2 hover:text-primary-600">
                  <div className="inline font-mono">{qna.question}</div>
                </summary>
                <p className="mx-6">{qna.answer}</p>
              </details>
            </li>
          ))}
        </ul>
        
      </div>
     </div>
  )
}

export default FrequentlyAskedQuestions