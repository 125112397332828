import NextImage from '../../elements/image'
import ButtonLink from '../../elements/button-link'
import { getButtonAppearance } from '../../../utils/button'


const FeatureColumnsGroup = ({ data }) => {
  return (
    <div className="container content-center">
      <div className="flex flex-col lg:flex-row lg:flex-wrap gap-8 align-top py-8">
        {data.features.map((feature) => (
          <div className="flex-1 text-base/6 text-center" key={feature.id}>
            <div className="w-24 h-24 mx-auto">
              <NextImage media={feature.icon} />
            </div>
            <div className="text-20 font-cursive text-crimson">{feature.title}</div>
            <p className="leading-5 text-sm">{feature.description}</p>
          </div>
        ))}
      </div>
      {/* Buttons row */}
      <div className="flex flex-row gap-4 text-crimson text-xl place-content-center w-full">
       {/* {data.buttons.map((button) => ( */}
          {/* <ButtonLink
            className="w-full"
            compact
            button={{
              id: "shopnow_2", text: "Shop Now", url: "/store", newTab: false, type: "primary"
            }}
            appearance={getButtonAppearance("dark", "primary")}
            key={"shopnow-2"}
         /> */}
        {/* ))} */}
      </div>
    </div>
  )
}

export default FeatureColumnsGroup