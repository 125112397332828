import { useState } from "react";
import { fetchRoute } from "../../../utils/api";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../elements/store-button";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactForm = ({ data }) => {
    // const [loading, setLoading] = useState(false)
    const [messageSent, setMessageSent] = useState(false)

    const schema = yup.object({
        firstName: yup.string().required(),
        lastName: yup.string(),
        email: yup.string().email('Invalid email address').required(),
        phone: yup.string(),
        message: yup.string().required(),
        createdAt: yup.date().default(() => new Date()),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
        reset();
        const response = await fetchRoute("/email-us", {}, {
            method: "POST",

            body: JSON.stringify(data),
        }).then(
            setMessageSent(true)
        ).catch(reason => { `caught ${reason}`})
    };

    return (
        <div className="py-10 text-left space-y-4">
            <div className="flex flex-col items-center"></div>
            <form onSubmit={handleSubmit(onSubmitHandler)}
                className="flex flex-col shadow-md rounded px-8 pt-6 pb-8 mb-4 justify-items-start">
                <div className="">
                    {/* <label className="" for="firstName">First name</label> */}
                    <input {...register("firstName")} className="border mx-2 my-2" type='text' id="firstName" placeholder="First name" />
                    <p>{errors.firstName?.message} </p>
                    {/* <label className="" for="lastName">Last name</label> */}
                    <input {...register("lastName")} className="border mx-2 my-2" type="text" id="lastName" placeholder="Last name" />
                    <p>{errors.lastName?.message} </p>
                </div>
                <div>
                    {/* <label className="" for="phone">Phone</label> */}
                    <input {...register("phone")} className="border mx-2 my-2" type='tel' id="phone" placeholder="Phone" />
                    <p>{errors.phone?.message} </p>
                </div>
                <div className="">
                    {/* <label className="" for="email">Email</label> */}
                    <input {...register("email")} className="border mx-2 my-2 w-90" type='email' id="email" placeholder="Email" />
                    <p> {errors.email?.message} </p>
                </div>
                <div>
                    {/* <label className="" for="message">Message</label> */}
                    <input {...register("message")} className="border mx-2 my-2" type='text' id="message" placeholder="Message" />
                    <p> {errors.message?.message} </p>
                </div>
                {messageSent === true && <p>Your message was sent. Someone will get back to you within 48 hours</p>}
                <Button className="my-4 mx-2 text-secondary" type="submit" variant="">Send Message</Button>
            </form>
        </div>)
}


export default ContactForm