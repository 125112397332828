
import ErrorPage from "next/error"
import Head from "next/head"
import { getPageData, fetchAPI, getGlobalData, getEvents } from "../utils/api"
import Sections from "../components/sections"
import Seo from "../components/elements/seo"
import { useRouter } from "next/router"
//import Layout from "../components/layout"
import { getLocalizedPaths } from "../utils/localize"

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const DynamicPage = ({ sections, metadata, preview, global, events, pageContext, error }) => {
    const router = useRouter()
    console.log(pageContext)

    // Loading screen (only possible in preview mode)
    if (router.isFallback) {
        return <div className="container">Loading...</div>
    }

    // Check for an error
    if (error) {
        return <ErrorPage statusCode={error.status} message={error.message} />
    }

    // Check if the required data was provided
    if (!router.isFallback && !sections?.length) {
        return <ErrorPage statusCode={404} />
    }

    
    // Merge default site SEO settings with page specific SEO settings
    if (metadata?.metaImage === null) {
        delete metadata.metaImage
    }
    const metadataWithDefaults = {
        ...global.metadata,
        ...metadata,
    }

    const canonicalHref = `https://brownedandtoasted.com/${pageContext.slug}`
    return (
        // <Layout global={global} pageContext={pageContext}>
        <div className="py-2">
            <Head>
                <link rel="canonical" href={canonicalHref} key="canonical"/>
            </Head>
        {/* Add meta tags for SEO*/}
        <Seo metadata={metadataWithDefaults} />
        {/* Display content sections */}
        <Sections sections={sections} events={events} preview={preview} key="_sections" />
        </div>
        // </Layout>
    )
}

export async function getStaticPaths(context) {
    // Get all pages from Strapi
    const pages = await context.locales.reduce(
        async (currentPagesPromise, locale) => {
            const currentPages = await currentPagesPromise
            const localePages = await fetchAPI("/pages", {
                locale
            })
            return [...currentPages, ...localePages.data]
        },
        Promise.resolve([])
    )

    const paths = pages.map((page) => {
        const { slug, locale } = page.attributes
        // Decompose the slug that was saved in Strapi
        const slugArray = !slug ? [''] : slug.split("/")
    
        return {
            params: { slug: slugArray },
            // Specify the locale to render
            locale,
        }
    })
    // We'll pre-render only these paths at build time.
    // { fallback: 'blocking' } will server-render pages
    // on-demand if the path doesn't exist.
    return { paths, fallback: 'blocking' }
}

export async function getStaticProps(context) {
    const { params, locale, locales, defaultLocale, preview = null } = context

    // fetch global properties
    const globalLocale = await getGlobalData(locale, preview)

    // fetch upcoming events
    const events = await getEvents(locale, preview)

    // Fetch pages. Include drafts if preview mode is on
    let p = {
        slug: (!params.slug ? [null] : params.slug).join("/"),
        locale,
        preview,
    }
    const pageData = await getPageData(p)

    if (pageData === null) {
        // Giving the page no props will trigger a 404 page
        return { props: { } }
    }
    if (pageData.error) {
        return { props: { error: pageData.error} }
    }

    // We have the required page data, pass it to the page component
    const { sections, metadata, localizations, slug } = pageData

    const pageContext = {
        locale,
        locales,
        defaultLocale,
        slug,
        localizations,
    }

    // console.log('pageContext = %s', pageContext)
    // console.log('sections = %s', sections)
    // console.log('globalLocale = %s', globalLocale)
    const localizedPaths = getLocalizedPaths(pageContext)
    return {
        props: {
            preview,
            sections: sections,
            metadata,
            global: globalLocale,
            events: events,
            pageContext: {
                ...pageContext,
                localizedPaths,
            },
            // turn on ISR
            revalidate: 5,
        },
    }

   // console.log("static props = %s", staticProps.props)
   // return staticProps
}

export default DynamicPage