import { DateTime } from "luxon"

const SellingEvent = ({event}) => {
    console.log(event)
    const startDate = DateTime.fromISO(event.startsAt)
    const endDate = DateTime.fromISO(event.endsAt)
    
    return(
    <div className="p-4">
        <div className="">{event.title} </div><div className="">{event.id}</div>
        <div className="">
            <span className="mr-4">{startDate.toLocaleString(DateTime.TIME_SIMPLE)}-</span><span className="m-4">{endDate.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        </div>
        <div className="">{event.address}</div>
    </div>
    )
}

export default SellingEvent