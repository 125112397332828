// components/ContactInfo.js

import React from 'react';
import Head from 'next/head';
import { EnvelopeIcon, PhoneIcon, PlusCircleIcon } from "@heroicons/react/24/solid"

const NapInfo = () => {
  return (
    <>
      <Head>
        {/* SEO Optimization */}
        <title>Contact Us | Browned & Toasted Gourmet Crispy Treats</title>
        <meta
          name="description"
          content="Get in touch with Browned & Toasted for inquiries about our gourmet crispy treats made with homemade marshmallow and browned butter. We're here to help!"
        />
        {/* Structured Data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Browned & Toasted",
              "image": "https://brownedandtoasted.com/images/logo.png",
              "@id": "",
              "url": "https://brownedandtoasted.com/",
              "telephone": "(206) 778-3616",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "19551 40th Pl. NE",
                "addressLocality": "Lake Forest Park",
                "addressRegion": "WA",
                "postalCode": "98155",
                "addressCountry": "US"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 47.771, 
                "longitude": -122.286
              },
            //   "openingHours": "Mo-Fr 10:00-17:00",
              "email": "orders@brownedandtoasted.com",
              "sameAs": [
                "https://www.facebook.com/brownedandtoasted",
                "https://www.instagram.com/brownedandtoasted",
                "https://www.tiktok.com/@brownedandtoasted"
              ]
            })
          }}
        />
      </Head>
      <section className="contact-info">
        {/* <h1>Contact Us</h1> */}
        <p>
          We&apos;d love to hear from you! For any questions or inquiries about our
          gourmet crispy treats crafted with our artisanal marshmallow and browned
          butter, please reach out to us using the information below.
        </p>
        <div className="contact-details">
          <h2>Browned &amp; Toasted</h2>
          <address>
            19551 40th Pl. NE<br />
            Lake Forest Park, WA<br />
            United States
          </address>
          <div>
            {/* <strong>Phone:</strong>{' '} */}
            <div className="flex items-center">
            <span className="flex w-5 h-5 shrink-0 grow-0 rounded-full bg-alert text-chocolate items-center justify-center">
                <PhoneIcon className="w-3 h-3 inline text-white" />
            </span>
            {/* <a className="px-2" href="tel:+12067783616">(206) 778-3616</a> */}
            <span className="px-2">(206) 778-3616</span>
            </div>
            <div className="flex items-center">
            <span className="flex w-5 h-5 shrink-0 grow-0 rounded-full bg-alert text-chocolate items-center justify-center">
                <EnvelopeIcon className="w-3 h-3 inline text-white" />
            </span>
            <span className="px-2">store@brownedandtoasted.com</span>
            {/* <strong>Email:</strong>{' '} */}
            {/* <a href="mailto:store@brownedandtoasted.com"> */}
              {/* store@brownedandtoasted.com */}
            {/* </a> */}
            </div>
          </div>
          <br/>
          <p>
            <strong>Business Hours:</strong>
            <br />
            Monday - Friday: By Appointment Only
            <br />
            Saturday - Sunday: Closed
          </p>
          <p>
            <strong>Follow us on:</strong>
            <br />
            <a
              href="https://www.facebook.com/brownedandtoasted"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>{' '}
            |{' '}
            <a
              href="https://www.instagram.com/brownedandtoasted"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>{' '}
            |{' '}
            <a
              href="https://www.tiktok.com/@brownedandtoasted"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tiktok
            </a>
          </p>
        </div>
      </section>
      <style jsx>{`
        .contact-info {
          padding: 20px;
        }
        .contact-details {
          margin-top: 20px;
        }
        address {
          font-style: normal;
          line-height: 1.6;
        }
        a {
          color: #0070f3;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

export default NapInfo;
