import { useRouter } from "next/router"
import Hero from "./sections/hero"
import LargeVideo from "./sections/large-video"
import FeatureColumnsGroup from "./sections/feature-columns-group"
import FeatureRowsGroup from "./sections/feature-rows-group"
import BottomActions from "./sections/bottom-actions"
import FrequentlyAskedQuestions from './sections/frequently-asked-questions'
import Testimonials from "./sections/testimonials"
import RichText from "./sections/rich-text"
import SimilarProducts from "./sections/similar-products"
import ContactUs from "./sections/contact-us"
import UpcomingEvents from "./sections/upcoming-events"
import Retailers from "./sections/retailers"
import SoftOffer from "./sections/soft-offer"
import { InView, useInView } from 'react-intersection-observer'
import { useRef, useState, useEffect } from 'react'

//import Pricing from "./sections/pricing"
//import LeadForm from "./sections/lead-form"

// Map Strapi sections to section components
const sectionComponents = {
  'sections.hero': Hero,
  'sections.large-video': LargeVideo,
  'sections.feature-columns-group': FeatureColumnsGroup,
  'sections.feature-rows-group': FeatureRowsGroup,
  'sections.bottom-actions': BottomActions,
  'sections.frequently-asked-questions': FrequentlyAskedQuestions,
  'sections.testimonials': Testimonials,
  'sections.rich-text': RichText,
  'sections.similar-products': SimilarProducts,
  'sections.contact-us': ContactUs,
  //ComponentSectionsPricing: Pricing,
  //ComponentSectionsLeadForm: LeadForm,
  'sections.upcoming-events': UpcomingEvents,
  'sections.retailers': Retailers, 
  'sections.soft-offer': SoftOffer,
}

// Display a section individually
const Section = ({ sectionData, events }) => {
  const domRef = useRef();
  // const [isVisible, setvisible] = useState(false)

  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component]

  //console.log(`displaying section with id: ${SectionComponent.name}${sectionData?.id}`)
  //console.log(SectionComponent)

  //sectionData.events = events
  const data = {
    ...sectionData,
    //events,
    events: events,
  }

  const { ref, inView, entry } = useInView({ threshold: 0.2 })

  // Display the section
  return (
  <div ref={ref} className={`bg-${data?.color?.color||'transparent'} text-${data?.color?.textColor||'primary'} transition-all duration-500 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
    {(SectionComponent != null) ?<SectionComponent data={data} /> : <></> } 
  </div>)
}

const PreviewModeBanner = () => {
  const router = useRouter()
  const exitURL = `/exit-preview?redirect=${encodeURIComponent(
    router.asPath
  )}`

  return (
    <div className="py-4 bg-red-600 text-red-100 font-semibold uppercase tracking-wide">
      <div className="container">
        Preview mode is on.{" "}
        <a className="underline"
          href={`/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  )
}

// Display the list of sections
const Sections = ({ sections, events, preview }) => {
 // console.log('Sections %s', sections.map(x => JSON.stringify(x)))
  return (<div className="flex flex-col">
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner key='_banner'/>}
      {/* Show the actual sections */}
      {sections.map((section) => (
          // <div className={`bg-${section?.color?.color||'transparent'}`} key={`section_div_${section.id}`}>
        <Section
          sectionData={section}
          events={events}
          key={`${section.__component}${section.id}`}
          />
      ))}
    </div>
  )
}

export default Sections