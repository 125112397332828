import OfferForm from "../../elements/offer-form"
import ButtonLink from "../../elements/button"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import { getButtonAppearance } from '../../../utils/button'


const SoftOffer = ({ data }) => {
    const button = data.ctaButton
    return <>
        <div className="container flex flex-col lg:flex-row gap-20 px-12 align-top py-12">
            <div className="flex-auto w-4/6">
                <p className="flex mb-2 font-sans-serif text-xl text-chocolate">{data.title}</p>
                <div className="prose text-chocolate container py-12 mb-2"><ReactMarkdown className="">{data.offer}</ReactMarkdown></div>
            </div>
            <details>
            <summary>
            {button != null &&
                // <ButtonLink
                //     compact
                //     button={button}
                //     appearance={getButtonAppearance(button.type, "dark")}
                //     key={button.id}
                // />
                button.text
                                }
            </summary>
            <OfferForm data={data} className="flex-auto w-2/6"></OfferForm>
            </details>
        </div>
    </>
}

export default SoftOffer