import ContactForm from "../../elements/contact-form"
import { EnvelopeIcon, PhoneIcon, PlusCircleIcon } from "@heroicons/react/24/solid"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import NapInfo from "../../elements/name-address-phone"


const ContactUs = ({ data }) => {
    return <>
        <div className="container flex flex-col md:flex-row align-top">
            <div className="flex-auto">
                <p className="flex mb-2 font-sans-serif text-xl text-chocolate">{data.title}</p>
                <div className="prose text-chocolate container py-12 mb-2"><ReactMarkdown className="">{data.description}</ReactMarkdown></div>
        <NapInfo/>
        </div>
        <ContactForm data={data} className="flex-auto "/>
        </div>
        </>
    {/* //             List of contact information */}
    {/* //             <ul> */}
    {/* //                 {data.contactInformation.map((contact) => ( */}
    {/* //                     <li key={contact.id} className="m-1"> */}
    {/* //                         <div className="flex items-center"> */}
    {/* //                             <div className="flex w-5 h-5 shrink-0 grow-0 rounded-full bg-alert text-chocolate items-center justify-center"> */}
    {/* //                                 {contact.contactType === "email" ? ( */}
    {/* //                                     <EnvelopeIcon className="w-3 h-3 inline text-white" /> */}
    {/* //                                 ) : contact.contactType === "telephone" ? ( */}
    {/* //                                     <PhoneIcon className="w-3 h-3 inline text-white" /> */}
    {/* //                                 ) : ( */}
    {/* //                                     <PlusCircleIcon className="w-4 h-4 inline text-white" /> */}
    {/* //                                 )} */}
    {/* //                             </div> */}
    {/* //                             <div className="pl-1 text-sm">{contact.info}</div> */}
    {/* //                         </div> */}
    {/* //                     </li> */}
    {/* //                 ))} */}
    {/* //             </ul> */}
    {/* //         </div> */}
    {/* //         <NapInfo/> */}
    {/* //         <ContactForm data={data} className="flex-auto w-2/6"/> */}
    {/* //     </div> */}
    {/* // </>  */}
}

export default ContactUs